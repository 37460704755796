
  import { Component, Watch } from 'vue-property-decorator'
  import { plainToInstance } from 'class-transformer'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixAmountFormatted, fixPrice, isValidNumber, parseToNumber, stringifySafe, toCamelCase } from '@/utils/general'
  import { Enablement } from '@/entities/purchase'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import { City } from '@/entities/public'
  import { Person } from '@/entities/persons'
  import dayjs from 'dayjs'
  import GCostField from '@/components/core/input/GCostField.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { Stock } from '@/entities/sales'
  import { DocumentationView } from '@/components/forms/view/DocumentationView'
  import { PaymentOrder, PaymentOrderItem, PaymentSupportType } from '@/entities/finance'
  import { Expense, ProcessExpense } from '@/entities/settings'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: { GAlert, GDatePicker, GCostField, GRadioButton, GFiles, LinkedPerson, BaseForm, AutoResume },
  methods: { fixAmountFormatted, fixPrice, isValidNumber },
  computed: {},
})
  export default class StockDocumentationForm extends DocumentationView {
  componentKeys = ['circulationPermit', 'soap', 'technicalReview']
  enablement: Enablement = plainToInstance(Enablement, {})
  stock: Stock = plainToInstance(Stock, {})
  components = []
  message = ''
  alert = {
    open: false,
    title: '',
    item: null,
  }

  newInsurance = null
  newTechnicalReview= null
  paymentSupportType: PaymentSupportType[] = []
  enablementTaskChip = null
  processEnablementTaskId = null
  lastOrder = null
  missingValue = ''
  lastDocument = []
  processInspectionId = null
  processEnablementId = null
  enablementInspectionComponent = []
  enablementTaskInspectionComponent = []
  vehicleCleaningCost: 0
  idInspectionInspectedComponent = null
  auto = null
  path = null
  task = null
  amount = null
  displayAmount = false
  disabledAmount = false
  showDetail = false
  total = null
  expiration = {
    circulationPermit: null,
    soap: null,
    technicalReview: null,
  }

  component = []
  cities: City[] = []
  plants: Person[] = []
  insurances: Person[] = []
  equipmentComponents = null
  errorMessage = {
    mileage: '',
  }

  errorIdentifierMessage = {
    status: {
      status: '',
      provisional: ''
      ,
    },
  }

  title = ''
  mileage = null
  panels = []
  section = {
    documents: false,
  }

  radioButtonMessage = {
    bodyWork: '',
    wheels: '',
    inside: '',
    mechanical: '',
    clean: '',
    answerDocuments: '',
  }

  formData = {
    soap: {
      photo: [],
      date: null,
      selection: null,
      cost: null,
      inspectionDate: null,
    },
    technicalReview: {
      photo: [],
      date: null,
      selection: null,
      cost: null,
      inspectionDate: null,
    },
    circulationPermit: {
      selection: null,
      date: null,
      amount: null,
      photo: [],
      cost: null,
      inspectionDate: null,
    },
    documentType: null,
    document: [],
    documentNumber: null,
    date: null,
  }

  fields = {
    key: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    mileagePhoto: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    circulationPermit: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    technicalReview: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    soap: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    document: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    bodyWork: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    wheels: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    inside: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    mechanical: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    frontPilot: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    rearPilot: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    chassis: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
    engine: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
      },
    },
  }

  metadata = {}
  metadataCollection = {}

  async mounted () {
    this.loadingForm = true

    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: {},
    })
    this.setTheBackup()
    await this.setMetadata()
    const { uid, id, title, metadataCollection } = this
    const stockId = uid || id

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
      limit: 100,
      force: true,
    })
    this.plants.push(plainToInstance(Person, { alias: 'Otra' }))

    if (isValidNumber(stockId)) {
      await this.getEnablementInfo(stockId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(stockId))
    }

    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
      force: true,
    })
    this.insurances = companyType?.[0]?.persons

    this.displayDetail()
    this.loadingForm = false
  }

  async setFormDataValues () {
    /*
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.enablementTask.id } },
          { parameter: { process: { id: { _eq: this.processEnablementId } } } },
        ],
      },
      force: true,
    })
    console.log(files)
    // this.formData.bodyWork.photo = files.filter(file => file.parameter.name === 'bodywork_cost')

     */
  }

  setTheBackup () {
    const { backup } = this

    if (!backup) {
      this.close()
      return
    }

    const isEnablementExpense = 'enablementExpenseForm' in backup && backup?.enablementExpenseForm
    const isStockDocumentationForm = 'stockDocumentationForm' in backup && backup?.stockDocumentationForm

    this.path = backup.path
    this.task = backup.task
    const path = toCamelCase(backup.path)

    if (isStockDocumentationForm) {
      this.formData[path] = isStockDocumentationForm[path]
      this.formData.date = isStockDocumentationForm.date
      this.formData.documentNumber = isStockDocumentationForm.documentNumber
      this.formData.document = isStockDocumentationForm.document
      this.lastOrder = isStockDocumentationForm.lastOrder
    }

    if (isEnablementExpense) {
      this.setExpense(isEnablementExpense)
    }
  }

  setExpense (expense) {
    let order = null
    if (!this.lastOrder) {
      order = plainToInstance(PaymentOrder, {
        items: [plainToInstance(PaymentOrderItem, {
          amount: parseToNumber(this.formData[toCamelCase(this.path)].cost),
          processExpense: plainToInstance(ProcessExpense, {
            expense: plainToInstance(Expense, {
              description: expense.expenseType.description,
              name: expense.expenseType.name,
            }),
          }),
        })],
      })

      order.items[0].recipients = [expense.recipient]
    } else {
      order = this.lastOrder
      order.items[0].recipients[0].payments.push(expense.recipient.payments[0])
    }

    this.lastOrder = order
  }

  async setMetadata () {
    this.loadingForm = true
    const { metadata } = this.getForm('StockDocumentation', 'inspector_stock_documentation')

    const { form } = metadata as Form
    this.title = form.title
    this.metadataCollection = metadata

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
    })

    this.processInspectionId = processInspection?.[0]?.id

    const processEnablement = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement' } },
    })
    this.vehicleCleaningCost = processEnablement?.[0]?.config.vehicle_cleaning_cost
    this.processEnablementId = processEnablement?.[0]?.id
    const processEnablementTask = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement_task' } },
    })

    this.processEnablementTaskId = processEnablementTask[0].id
    const equipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Equipamiento' } },
    })

    this.equipmentComponents = equipment?.[0]?.components

    this.components = await this.fetchData({
      query: { name: 'find', model: 'InspectionComponent' },
      filter: { _or: [{ component: { category: { name: { _in: ['Documentación'] } } } }] },
    })

    this.paymentSupportType = await this.fetchData({
      query: { name: 'find', model: 'PaymentSupportType' },
      filter: { name: { _eq: 'ticket' } },
    })
    this.formData.documentType = this.paymentSupportType[0]

    // await this.setFormDataValues()
  }

  async getEnablementInfo (id) {
    if (!id) return
    this.stock = await this.fetchData({
      query: { name: 'fetch', model: 'Stock', params: { id } },
    })

    this.enablement = plainToInstance(Enablement, { id: this.stock.viewDetails.idEnablement })
    this.enablement.stock = plainToInstance(Stock, { id: this.stock.id })
    this.enablement.inspectionView = await this.fetchData({
      query: { name: 'find', model: 'InspectionView' },
      filter: { id_enablement: { _eq: this.enablement.id } },
    })

    const stockViewDetails = await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { stock: { id_enablement: { _eq: this.stock.viewDetails.idEnablement } } },
    })
    this.enablement.stock.viewDetails = stockViewDetails[0]

    const idAuto = this?.stock?.viewDetails?.idAuto
    if (idAuto) {
      this.auto = await this.fetchData({
        query: { name: 'fetch', model: 'Auto', params: { id: idAuto } },
      })
    }

    this.enablement.auto = this.auto

    if (this.task) {
      this.enablementTaskInspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: this.task.id } },
            { process: { table_name: { _eq: 'enablement_task' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })
    } else {
      this.enablementInspectionComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionQualification' },
        filter: {
          component: {
            _and: [
              { id_process_record: { _eq: this.enablement.id } },
              { process: { table_name: { _eq: 'enablement' } } },
              {
                _or: [
                  { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
                ],
              },
            ],
          },
        },
        force: true,
     })
    }

    await this.setFilesData()
    this.loadingForm = false
  }

  async setFilesData () {
    const { circulationPermit, soap, technicalReview, task } = this

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'inspection_inspected_component' } } },
    })

    this.setProperties(fileInfo, 'circulationPermit')
    this.setProperties(fileInfo, 'soap')
    this.setProperties(fileInfo, 'technicalReview')
    this.setProperties(fileInfo, 'document')

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })
    this.idInspectionInspectedComponent = process?.[0]?.id

    if (!task?.id) { // || task?.closingReason?.id
      await this.getLastFileEnablement(process)
     return
    }
    if (circulationPermit?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(circulationPermit[0].idInspectedComponent, 'circulationPermit', process?.[0]?.id)
    }
    if (soap?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(soap[0].idInspectedComponent, 'soap', process?.[0]?.id)
    }
    if (technicalReview?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(technicalReview[0].idInspectedComponent, 'technicalReview', process?.[0]?.id)
    }
  }

  async getLastFileEnablement (process) {
    if (!toCamelCase(this.path)) return

    const idProcessRecord = this[toCamelCase(this.path)]?.[0]?.idInspectedComponent

    if (!idProcessRecord) return

    this.lastDocument = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: process?.[0]?.id } } } },
          { parameter: { name: { _eq: 'photo_inspection' } } },
        ],
      },
      force: true,
    })
  }

  async setPhotoToForm (idProcessRecord, fieldKey, idProcess) {
    if (!idProcessRecord) return

    this.formData[fieldKey].photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    const { getInspectionViewByOrder } = this

    const date = getInspectionViewByOrder(this[fieldKey], 2)?.value

    this.formData[fieldKey].date = DateGenerator.findGeneratedDate(date).internal
    this.formData[fieldKey].inspectionDate = DateGenerator.findGeneratedDate(date).internal
    if (fieldKey === 'circulationPermit') {
      const answer = getInspectionViewByOrder(this[fieldKey], 1)?.value
      if (answer) {
        this.formData[fieldKey].selection = { name: answer }
      }

      this.formData[fieldKey].amount = getInspectionViewByOrder(this[fieldKey], 3)?.value
    }

    if (fieldKey === 'soap') {
      const answer = getInspectionViewByOrder(this[fieldKey], 1)?.value

      if (answer) {
        this.formData[fieldKey].selection = { alias: answer }
      }
    }

    if (fieldKey === 'technicalReview') {
      const answer = getInspectionViewByOrder(this[fieldKey], 1)?.value
      if (answer) {
      const plant = this.plants.find(p => p.name === answer || p.alias === answer)
      this.formData[fieldKey].selection = { alias: plant?.alias }
    }
    }

    this.formData[fieldKey].cost = getInspectionViewByOrder(this[fieldKey], 1)?.component.cost
  }

  setPropertiesBySlug (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  displayDetail () {
    const { enablement, metadataCollection } = this

    this.metadata = {
      data: enablement,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(enablement.id)
  }

  sectionValidation () {
    const keys = ['answerDocuments']
    let response = false
    keys.forEach(key => {
      if (this.formData[key] === null || this.formData[key] === undefined) {
        this.radioButtonMessage[key] = 'Campo requerido'
        response = true
      }
    })

    return response
  }

  focusFirstError () {
    // Lista de refs de todos los campos a validar
    const fields = [
      'mileage',
      'mileagePhoto',
      'key',
      'keyPhoto',
      'circulationPermit',
      'circulationPermitPhoto',
      'circulationPermitDate',
      'soap',
      'soapPhoto',
      'soapDate',
      'technicalReview',
      'technicalReviewPhoto',
      'technicalReviewDate',
      'bodyWork',
      'wheels',
      'inside',
      'mechanical',
      'clean',
    ]

    for (const field of fields) {
      const ref = this.$refs[field]
      if (ref && ref.validate && !ref.validate()) {
        // Abrir el panel si el campo está dentro de un panel colapsable
        if (this.panels) {
          const panelIndex = this.getPanelIndex(field)
          if (panelIndex !== -1) {
            if (!this.panels?.length) {
              this.panels = [panelIndex]
            } else {
              this.panels.push(panelIndex)
            }
          }
        }

        // Enfocar el campo con error
        ref.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        ref.focus()
        break
      }
    }
  }

  get calculatedExpenses () {
    const { lastOrder } = this

    if (lastOrder?.payments?.length) {
      this.missingValue = ''
    }

    return lastOrder?.payments
  }

  getPanelIndex (field) {
    // Mapeo de campos a índices de los panels
    const panelMapping = {
      mileage: 0,
      mileagePhoto: 0,
      key: 1,
      keyPhoto: 1,
      circulationPermit: 2,
      circulationPermitPhoto: 2,
      circulationPermitDate: 2,
      soap: 2,
      soapPhoto: 2,
      soapDate: 2,
      technicalReview: 2,
      technicalReviewPhoto: 2,
      technicalReviewDate: 2,
      bodyWork: 3,
      wheels: 4,
      inside: 5,
      mechanical: 6,
      clean: 7,
    }

    return isValidNumber(panelMapping[field]) ? panelMapping[field] : -1
  }

  async send () {
    const { expenseError, calculatedExpenses } = this
    if (!this.$refs.form.validate() || expenseError.length || !calculatedExpenses?.length) {
      if (!calculatedExpenses?.length) this.missingValue = 'Debe registrar el gasto'
      this.$refs.form.validate()
      await this.setFieldError()
      this.focusFirstError()
      return
    }

    this.loadingForm = true

    await this.handleDocument(this)
    this.loadingForm = false
    await this.close()
  }

  get change () {
    const { formData, panels, loadingForm } = this

    return stringifySafe([formData, panels, loadingForm])
  }

  get enablementIsToUpdate () {
    const { enablement } = this
    return enablement?.status?.isToUpdating || enablement?.status?.isPending || enablement?.status?.isActive
  }

  get enablementStatusIsDisabled () {
    const { enablement } = this

    return enablement?.status?.isClosed || enablement?.status?.isToConfirm
  }

  get basicEquipment () {
    return this.findComponentBySlug(this.equipmentComponents, 'basic_equipment')
  }

  get keyComponent () {
    return this.basicEquipment?.values.find(val => val.value === 'Llaves')
  }

  get keyInspection () {
    const { enablement: { inspectionKey } } = this
    return inspectionKey?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')
  }

  get key () {
    const { enablement: { inspectionKey }, enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')

      if (components.length) {
        return components
      }
    }

    return inspectionKey?.filter(ic => ic.component?.inspectionComponent?.componentValue?.value === 'Llaves')
  }

  get mileageComponent () {
    const { enablement: { inspectionIdentifiers }, enablementInspectionComponent } = this
    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'mileage')

      if (components.length) {
        return components
      }
    }

    return inspectionIdentifiers?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'mileage')
  }

  get circulationPermit () {
    const { enablementInspectionComponent, enablementTaskInspectionComponent } = this

    const inspectionComponents = enablementTaskInspectionComponent?.length ? enablementTaskInspectionComponent : enablementInspectionComponent

    if (inspectionComponents?.length) {
      const components = inspectionComponents?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')

      if (components.length) {
        return components
      }
    }

    return null
  }

  get oldCirculationPermit () {
    const { enablementInspectionComponent } = this

    return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
  }

  get soap () {
    const { enablementInspectionComponent, enablementTaskInspectionComponent } = this

    const inspectionComponents = enablementTaskInspectionComponent?.length ? enablementTaskInspectionComponent : enablementInspectionComponent

    if (inspectionComponents?.length) {
      const components = inspectionComponents?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
      if (components.length) {
        return components
      }
    }

    return null
  }

  get oldSoap () {
    const { enablementInspectionComponent } = this

    return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
  }

  get technicalReview () {
    const { enablementInspectionComponent, enablementTaskInspectionComponent } = this

    const inspectionComponents = enablementTaskInspectionComponent?.length ? enablementTaskInspectionComponent : enablementInspectionComponent

    if (inspectionComponents?.length) {
      const components = inspectionComponents?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
      if (components.length) {
        return components
      }
    }

    return null
  }

  get oldTechnicalReview () {
    const { enablementInspectionComponent } = this

    return enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
  }

  get totalExpenses () {
    const { formData, calculatedExpenses, path } = this

    const cost = formData[toCamelCase(path)].cost

    if (!calculatedExpenses?.length || !cost) return 0

    return calculatedExpenses.reduce((acc, payment) => acc + parseToNumber(payment?.amount), 0)
  }

  get isOverPrice () {
    const { formData, totalExpenses, path } = this

    if (!totalExpenses) return false

    const cost = formData[toCamelCase(path)].cost
    return parseToNumber(totalExpenses) > parseToNumber(cost)
  }

  get isExpenseFull () {
    const { formData, totalExpenses, path } = this

    if (!totalExpenses) return false
    const cost = formData[toCamelCase(path)].cost
    return parseToNumber(totalExpenses) < parseToNumber(cost)
  }

  get expenseError () {
    const { formData, path, isOverPrice, lastOrder, totalExpenses, isExpenseFull, missingValue } = this

    if (missingValue?.length) return missingValue

    if (isOverPrice) return 'El gasto no puede ser mayor al monto del documento'

    if (!lastOrder?.payments?.length) return ''
    const cost = formData[toCamelCase(path)].cost
    return !isExpenseFull ? '' : `Se debe cubrir el monto del documento ${fixPrice(parseToNumber(cost) - parseToNumber(totalExpenses))}`
  }

  get allComponents () {
    const { circulationPermit, soap, technicalReview, key, mileageComponent } = this

    return {
      circulationPermit,
      soap,
      technicalReview,
      key,
      mileageComponent,
    }
  }

  get sendCirculation () {
    const { path } = this

    return path === 'circulation_permit'
  }

  get sendSoap () {
    const { path } = this

    return path === 'soap'
  }

  get sendTechnicalReview () {
    const { path } = this

    return path === 'technical_review'
  }

  get isFilledCirculation () {
    const { formData: { circulationPermit: { date, selection, cost, photo } } } = this

    return Boolean(date) && Boolean(selection?.id) && Boolean(parseInt(cost)) && Boolean(photo?.length)
  }

  get isFilledSoap () {
    const { formData: { soap: { date, selection, cost, photo } }, newInsurance } = this
    const isSelection = selection?.id ? true : selection?.alias === 'Otra' && newInsurance
    return Boolean(date && isSelection && parseInt(cost) && photo?.length)
  }

  get isFilledTechnicalReview () {
    const { formData: { technicalReview: { date, selection, cost, photo } } } = this

    return Boolean(date && selection && parseInt(cost) && photo?.length)
  }

  get isFilledSome () {
    const { isFilledSoap, isFilledCirculation, isFilledTechnicalReview } = this

    return isFilledCirculation || isFilledSoap || isFilledTechnicalReview
  }

  get isSomeSelectionOther () {
    const { formData: { circulationPermit }, newMunicipality } = this

    return Boolean(
      (circulationPermit?.selection?.alias === 'Otra' && newMunicipality)
    )
  }

  @Watch('allComponents', { immediate: true, deep: true })
  async onComponentsChange (val) {
    if (!val) return

    await this.setFilesData()
  }

  @Watch('formData.mileage', { immediate: true, deep: true })
  onMileageValueChange (val) {
    if (!val) return

    if (Number(val) < Number(this.mileage?.value)) {
      this.errorMessage.mileage = 'El kilometraje no puede ser menor al registrado en la inspección'
    } else {
      this.errorMessage.mileage = ''
    }
  }

  async searchCity (val) {
    if (!val) return
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _ilike: `%${val}%` } },
      limit: 100,
    })
  }

  async searchCompany (val) {
    if (!val) return

    this.insurances = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: `insurance_carrier` } }, alias: { _ilike: `%${val}%` } },
      force: true,
    })
  }

  async searchPlants (val) {
    if (!val) return

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { _and: [{ company_type: { name: { _eq: `technical_review_plant` } } }, { alias: { _ilike: `%${val}%` } }] },
      limit: 100,
    })
    this.plants.push(plainToInstance(Person, { alias: 'Otra' }))
  }

  get isCalculableCost () {
    const { formData } = this

    return {
      amount: formData.circulationPermit.amount,
      date: formData.circulationPermit.date,
    }
  }

  @Watch('isCalculableCost', { immediate: true, deep: true })
  onCalculableCost (val) {
    if (this.loadingForm) return
    if (val.amount) {
      this.formData.circulationPermit.cost = Math.round(parseToNumber(val.amount) * this.delayPeriod)
    }
  }

  get delayPeriod () {
    const { circulationPermit, formData } = this

    const date = circulationPermit?.[0]?.date || dayjs()

    return DateGenerator.calculateMonthsDifference(formData.circulationPermit.date, date)
  }

  get expirationCirculation () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.circulationPermit.date) || -1
  }

  get enablementHasInspection () {
    const { enablement } = this

    return Boolean(enablement?.inspectionView?.length)
  }

  get expirationPeriodSoap () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.soap.date)
  }

  get expirationPeriodTechnicalReview () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.technicalReview.date)
  }

  get ppu () {
    const { auto } = this

    return auto?.ppu?.split('')[auto?.ppu.length - 1]
  }

  get autoYear () {
    const { auto } = this

    return auto?.year
  }

  get technicalDates () {
    const { ppu, autoYear } = this

    if (!ppu) return []
    const currentYear = dayjs().year()
    const currentMonth = dayjs().month() + 1

    let dates = DateGenerator.calculateMonthByDigit(parseToNumber(ppu), parseToNumber(autoYear))
    const monthMap = {
      Mar: '03',
      Ago: '08',
      Ene: '01',
      Feb: '02',
      Abr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dic: '12',
    }
    dates = dates.filter(date => {
      const year = parseInt(date.internal.match(/\d{4}/)[0])
      let fixDate = date.internal
      for (const [key, value] of Object.entries(monthMap)) {
        fixDate = fixDate?.replace(key, value)
      }

      const monthGenerate = dayjs(fixDate, 'DD/MM/YYYY').month() + 1

      if (year > currentYear) {
        return true
      }

      if (year === currentYear) {
        return monthGenerate >= currentMonth
      }

      return false
    })

    return dates
  }

  evaluateDeadline (deadline) {
    if (deadline === null || deadline === undefined) {
      return null
    }
    if (deadline < 0) {
      return 2
    }
    if (deadline === 0) {
      return 1
    }
    if (deadline > 0) {
      return 0
    }
  }

  @Watch('auto', { immediate: true, deep: true })
  async onInspectionChange (val) {
    if (!val?.id) return

    const { generation } = val
    let gen

    if (generation?.id) {
      gen = await this.fetchData({
        query: { name: 'fetch', model: 'Generation', params: { id: generation.id } },
      })
    }
    const { enablement } = this
    const date = enablement.inspectionView?.[0]?.date?.year() || dayjs().year()
    const registration = gen?.registration?.find(registration => parseToNumber(registration.year) === parseToNumber(date))

    this.amount = registration?.permission
    if (!this.formData.circulationPermit.amount) {
      this.formData.circulationPermit.amount = registration?.permission
    }
    this.displayAmount = true
    this.disabledAmount = Boolean(registration?.permission)

    if (!isValidNumber(this.total) && val.metadata?.findCategoryByName('Documentación')) {
      this.total = val.metadata?.findCategoryByName('Documentación')?.total
    }
  }

  @Watch('formData.circulationPermit.amount', { immediate: true })
  onAmountChange (val) {
    if (!val || this.loadingForm) return

    const { amount } = this
    if (!amount) return
    const cost = amount - parseToNumber(val)

    if (cost > 0) {
      this.formData.circulationPermit.cost = cost
    } else if (!this.loadingForm) {
      this.formData.circulationPermit.cost = 0
    }
  }

  get generateDates () {
    const currentYear = dayjs().year()
    const dates = DateGenerator.generateDates(currentYear, true)

    return this.filterDates(dates)
  }

  filterDates (dates) {
    const currentYear = dayjs().year()
    const currentMonth = dayjs().month() + 1 // Mes indexado (0 = Enero)
    const monthMap = {
      Mar: '03',
      Ago: '08',
      Ene: '01',
      Feb: '02',
      Abr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dic: '12',
    }
    return dates.filter(date => {
      const year = parseInt(date.internal.match(/\d{4}/)[0])
      let replacedDate = date.internal
      for (const key in monthMap) {
        if (replacedDate.includes(key)) {
          replacedDate = replacedDate.replace(key, monthMap[key])
          break
        }
      }
      const monthGenerate = dayjs(replacedDate, 'DD/MM/YYYY').month() + 1
      if (monthGenerate === currentMonth) {
        return year > currentYear
      }
      if (monthGenerate > currentMonth) {
        return year >= currentYear
      }
      return false
    })
  }

  get generateSoapDates () {
    const currentYear = dayjs().year()
    const dates = DateGenerator.generateDates(currentYear, true, false)

    return this.filterDates(dates)
  }

  get isCirculationPermit () {
    const { circulationPermit } = this

    return circulationPermit?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isSoap () {
    const { soap } = this

    return soap?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isTechnicalReview () {
    const { technicalReview } = this

    return technicalReview?.find(iv => iv.parameter.order === 1)?.componentResponse
  }

  get isNewVehicle () {
    const { enablement } = this

    return !enablement.inspectionView?.length
  }

  getIcon (expiration) {
    if (expiration < 0) {
      return 'mdi-close-circle-outline'
    }

    if (expiration < 3) {
      return 'mdi-clock-outline'
    }

    return 'mdi-check-circle-outline'
  }

  getIconColor (expiration) {
    if (expiration < 0) {
      return 'red'
    }

    if (expiration < 3) {
      return 'orange'
    }

    return 'green'
  }

  getIconText (expiration) {
    if (expiration < 0) {
      return 'Vencido'
    }

    if (expiration < 3) {
      return 'Por vencer'
    }

    return 'Vigente'
  }

  getIsEditable (expiration) {
    return expiration < 3
  }

  @Watch('expirationCirculation', { immediate: true })
  onExpirationCirculationChange (val) {
    if (this.loadingForm) return

    if (val < 0 && this.enablement?.inspectionView?.length && !this.expiration.circulationPermit) {
      this.expiration.circulationPermit = true
      this.formData.circulationPermit.date = null
      this.formData.circulationPermit.selection = null
      this.formData.circulationPermit.photo = []
    }
  }

  get technicalReviewInfo () {
    const { expirationPeriodTechnicalReview, components } = this

    return {
      expirationPeriodTechnicalReview,
      components,
    }
  }

  saveBackup () {
    const { backup, formData, path, lastOrder } = this

    if (backup) {
      backup.stockDocumentationForm = { ...formData, path, lastOrder }
      this.setBackup(backup)
    } else {
      this.setBackup({ stockDocumentationForm: { ...formData, path, lastOrder } })
    }
  }

  async createInsurance () {
    const { newInsurance } = this

    if (!newInsurance) return

    const personType = (await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
    }))[0]

    this.formData.soap.selection = await this.pushData({
      model: 'Person',
      fields: {
        alias: newInsurance.toUpperCase(),
        id_company_type: personType.id,
        name_person_type: 'company',
      },
    })
  }

  async createTechnicalReview () {
    const { newTechnicalReview } = this

    if (!newTechnicalReview) return

    const personType = (await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'technical_review_plant' } },
    }))[0]

    this.formData.technicalReview.selection = await this.pushData({
      model: 'Person',
      fields: {
        alias: newTechnicalReview.toUpperCase(),
        id_company_type: personType.id,
        name_person_type: 'company',
      },
    })
  }

  async createEnablementTask () {
    await this.createInsurance()
    await this.createTechnicalReview()

    this.saveBackup()
    await this.$router.push({
      name: 'generic-enablement-task',
      params: {
        model: 'EnablementTask',
        uid: 'create',
      },
    }).catch(() => {})
  }

  enablePayment (item) {
    return item.id && item.status && (item.status.name !== 'closed' && item.status.name !== 'pending_payment')
  }

  confirmRemoveExpense (item) {
    this.alert = {
      open: true,
      title: '¿Está seguro que desea eliminar el gasto?',
      item,
    }
  }

  async removeExpense (item) {
    for (let i = 0; i < this.lastOrder?.items.length; i++) {
      const recipients = this.lastOrder?.items[i].recipients
      const pos = recipients.findIndex(recipient => this.findLocalPos(recipient, {
        beneficiary: item.person,
        paymentType: item.paymentType,
        account: item.account,
        type: item.type,
        amount: item.amount,
      }))
      if (pos !== -1) {
        const recipient = this.lastOrder.items[i].recipients[pos]
        const itemType = this.lastOrder.items[i]

        const posPayment = recipient?.payments?.findIndex(_ => this.findLocalPayment(_, recipient.person, itemType, item))

        if (posPayment !== -1) {
          if (this.lastOrder.items[i].recipients?.length > 1) {
            this.lastOrder.items[i].recipients[pos]?.payments.splice(posPayment, 1)
          } else {
            this.lastOrder.items.splice(i, 1)
          }
        }
      }
      if (!this.lastOrder?.items?.length) {
        this.lastOrder = null
      }

      this.alert.open = false
    }
  }

  findLocalPos (recipient, val) {
    if (recipient?.id) return false
    return recipient?.person?.id === val?.beneficiary?.id &&
      recipient.payments.find(payment =>
        (payment?.recipientAccount?.id && val?.account?.id && payment?.recipientAccount?.id === val?.account?.id) ||
        (payment?.type?.id && val?.paymentType?.name === 'ok_view' && payment?.type?.id === val?.paymentType?.id) ||
        (payment?.amount === val?.amount)
      )
  }

  findLocalPayment (payment, person, type, val) {
    return !payment.id && type?.name === val?.type &&
      ((val?.account?.id && payment?.recipientAccount?.id === val?.account?.id) ||
        (val?.paymentType?.id && payment?.type?.id === val?.paymentType?.id) ||
        (val?.paymentRecipient?.person?.id === person.id && val?.amount === payment?.amount))
  }
  }
